import logoMore from "../assets/img/logoMore.png";
import faviconMore from "../assets/img/faviconMore.png";

export const GLOBAL_CONSTANTS = {
  MORE_LOGO_PATH: logoMore,
  MORE_FAVICON: faviconMore,

  API_OAUTH_DOMAIN: process.env.REACT_APP_API_OAUTH_DOMAIN,
  API_OAUTH_CLIENT_ID: process.env.REACT_APP_OAUTH_CLIENT_ID,
  API_CALLBACK_URL: process.env.REACT_APP_CALLBACK_URL,
  //
  AGGREGATOR_ROLE: 1,
  MERCHANT_ROLE: 2,
  //
  TRN_CHANGE_STATUS_ACTION: 1,
};

export const CATALOGUE_URL_CONSTANTS = {
  getCountriesCatalogue: "catalogue/selectOption/getCountries",
  getStatesCatalogue: "catalogue/selectOption/getStates", // ?countryId=1
  getCitiesCatalogue: "catalogue/selectOption/getCities", // ?countryId=1&stateId=1
  getDocumentTypesCatalogue: "catalogue/selectOption/getDocumentsType",
  getConnectionModesCatalogue: "catalogue/selectOption/getConnectionMode",
  getMerchantAggregatorRolesCatalogue: "catalogue/selectOption/getMerchantAggregatorRole",
  getCurrenciesCatalogue: "catalogue/selectOption/getCurrency",
  getEnabledCurrenciesByTokenCatalogue: "catalogue/selectOption/getListComboCurrency",
  getCalculationTypesCatalogue: "catalogue/selectOption/getCalculationType",
  getCalculationRangeModalitiesCatalogue: "catalogue/selectOption/getCalculationRangeModality",
  getCalculationRangeTypesCatalogue: "catalogue/selectOption/getCalculationRangeType",
  getMerchantsCatalogue: "catalogue/selectOption/getListMerchant",
  getAggregatorsCatalogue: "catalogue/selectOption/getListAggregator",
  getDataSourceConnectionsCataloge: "catalogue/selectOption/dataSourceTypeConnection",
  getActivitiesCatalogue: "catalogue/selectOption/getListActivities",
  getTaxTypesCatalogue: "catalogue/selectOption/getListTaxTypes",
  getMerchantAggregatorsCatalogue: "catalogue/merchantAggregator?page=0&size=999999&sort=name,asc",
  getAuth0Roles: "catalogue/administration/getAllRoles",
  getAmountTypesCatalogue: "catalogue/selectOption/getInputAmountTypes",
  getPasarela: "catalogue/selectOption/getPasarela",
  getPaymentProviderStructure: "catalogue/selectOption/getPaymentProviderStructure",
  getPaymentMethods: "pricing/paymentMethods",
};

export const ALERT_TYPES = {
  success: "success",
  error: "danger",
};

export const MERCHANT_AGGREGATOR_ROLES = {
  aggregator: 1,
  merchant: 2,
  merchantAggregator: 3,
};

export const MANDATORY_FIELD_TYPES = {
  string: 1,
  number: 2,
  date: 3,
  select: 4,
};

export const HTTP_STATUS_CODES = {
  NoContent: 204,
};

export const TRANSACTION_STATUS = {
  pending: 0,
  finished: 1,
  error: 2,
  new: 3,
  approved: 4,
  rejected: 5,
  refunded: 6,
  preAuthorized: 7,
};

export const TRANSACTION_STATUS_ENUM = [
  { label: "Pending", value: 0 },
  { label: "Finished", value: 1 },
  { label: "Error", value: 2 },
  { label: "New", value: 3 },
  { label: "Approved", value: 4 },
  { label: "Rejected", value: 5 },
  { label: "Refunded", value: 6 },
  { label: "Pre-Authorized", value: 7 },
];

export const TRANSACTION_ACTIONS_ENUM = [{ label: "Change status", value: 1 }];

// COLORES
export const ColorOrangeMoreMT = "#f07c00";
