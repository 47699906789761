import React, { createContext, useState, useContext } from "react";

const TenantContext = createContext();

export const TenantProvider = ({ children }) => {
  const [tenant, setTenant] = useState();
  return <TenantContext.Provider value={{ tenant, setTenant }}>{children}</TenantContext.Provider>;
};

export const useTenant = () => useContext(TenantContext);
