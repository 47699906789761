import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { getApiUrl } from "utils/Api";
import { ColorOrangeMoreMT } from "utils/Constants";

const PasswordExpiredComponent = (props) => {
  const [emailSent, setEmailSent] = useState(false);

  const { logout } = useAuth0();

  const handleLoginAgain = async () => {
    try {
      logout({ logoutParams: { returnTo: window.location.origin } });
    } catch (error) {
      console.error(error);
    }
  };

  const toggle = () => {
    handleLoginAgain();
    props.setModal(!props.modal);
  };

  const handleResetPassword = () => {
    return axios.post(`${getApiUrl()}merchantdashboard/resetPassword`, {
      email: props.userEmail,
    });
  };

  useEffect(() => {
    if (props.modal && !emailSent) {
      const params = new URLSearchParams(location.search);

      handleResetPassword()
        .then(() => {
          setEmailSent(true);
        })
        .catch((error) => {
          setEmailSent(true);
          console.error("Error resetting password:", error);
        });
    }
  }, [props.modal]);

  return (
    <div>
      <Modal isOpen={props.modal} toggle={toggle}>
        <ModalBody>
          <Container fluid>
            <Row className="justify-content-center text-center mb-5 mt-5">
              <Col md={12}>
                <FontAwesomeIcon
                  icon="far fa-times-circle"
                  style={{ fontSize: "80px", color: "#ca1a1a" }}
                />
              </Col>
              <h3 className="mt-4 font-weight-bold">Email password expired</h3>
              <h5>{`We will send an email to "${props.userEmail}" so you can reset the password in a few seconds.`}</h5>
              <br />
              If you can't reset your password, please contact us at:
              <h6>
                <a
                  style={{ color: ColorOrangeMoreMT }}
                  href="mailto:helpdesk@morepaymentevolution.com"
                >
                  helpdesk@morepaymentevolution.com
                </a>
              </h6>
            </Row>
          </Container>
        </ModalBody>
        <ModalFooter className="d-flex justify-content-center">
          <Button color="primary" onClick={toggle}>
            Redirect to login
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PasswordExpiredComponent;
