import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import PayinLayout from "components/pages/Payin/PayinLayout";
import "./styles.scss";
import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import CustomLoader from "components/shared/CustomLoader";
import React, { createContext, useRef, useState } from "react";
import { AlertProvider } from "components/shared/AlertContext";
import { TenantProvider } from "components/shared/TenantContext";
import { useDispatch } from "react-redux";
import jwtDecode from "jwt-decode";
import { setAuthData } from "store/slices/authSlice";
import { resetAuthData } from "store/slices/authSlice";
import PasswordExpiredComponent from "components/shared/PasswordExpiredComponent";
import { Container } from "reactstrap";
import Navbar from "components/shared/Navbars/Navbar";

const App = () => {
  const [passwordExpired, setPasswordExpired] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [hasPermission, setHasPermission] = useState(false);

  const {
    isLoading,
    loginWithRedirect,
    user,
    isAuthenticated,
    error,
    getAccessTokenSilently,
  } = useAuth0();

  const dispatch = useDispatch();

  const handleLoginAgain = async () => {
    try {
      await loginWithRedirect();
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const login = async () => {
      try {
        if (!isLoading && !isAuthenticated && !user) {
          if (
            error &&
            error.message?.includes(
              "Your password has expired. Please reset it."
            )
          ) {
            setUserEmail(error.message.split("|")[1]);
            setPasswordExpired(true);
          } else {
            await handleLoginAgain();
          }
        } else if (isAuthenticated) {
          await setRolesAndPermission();
        }
      } catch (err) {
        console.error("Error during authentication:", err);
      }
    };

    login();
  }, [isLoading, isAuthenticated, user, error]);

  const loaderStyle = {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  const setRolesAndPermission = async () => {
    try {
      const token = await getAccessTokenSilently({
        cacheMode: "cache-only", // Solo usa el token en caché si está disponible.
      });
      if (token) {
        const decode = jwtDecode(token);
        dispatch(setAuthData(decode));
        if (
          decode.userRol.includes("BACKOFFICE_PAYIN_ACCESS") ||
          decode.userRol.includes("ADMIN_PAYIN")
        ) {
          setHasPermission(true);
        }
      } else {
        dispatch(resetAuthData());
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      // Puedes manejar un reintento o redirigir al login si es necesario
      if (error.error === "login_required") {
        await handleLoginAgain();
      }
    }
  };

  if (isLoading)
    return (
      <div style={loaderStyle}>
        <CustomLoader />
      </div>
    );
  if (passwordExpired) {
    return (
      <PasswordExpiredComponent
        setModal={setPasswordExpired}
        userEmail={userEmail}
        modal={passwordExpired}
      />
    );
  }

  return (
    isAuthenticated &&
    (hasPermission ? (
      <BrowserRouter>
        <TenantProvider>
          <AlertProvider>
            <Switch>
              <Route
                path="/payin"
                render={(props) => <PayinLayout {...props} />}
              />
              <Redirect to="/payin" />
            </Switch>
          </AlertProvider>
        </TenantProvider>
      </BrowserRouter>
    ) : (
      <>
        <TenantProvider>
          <Navbar isLoginView={true} />
          <Container
            fluid
            className="screen-height d-flex align-items-center justify-content-center"
            style={{ height: "85vh" }}
          >
            <h5>You don't have access to this application.</h5>
          </Container>
        </TenantProvider>
      </>
    ))
  );
};

export default App;
