import React, { lazy, useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Container,
  Col,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import "./Navbar.scss";
import routes from "routes.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDoorOpen,
  faCircleChevronLeft,
  faCircleChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import { components } from "react-select";
import { useEffect } from "react";
import { buildEnabledCountriesOptions } from "utils/Utils";
import { searchCountry } from "components/pages/Payin/Util/Country/Api/CountryApi";
import Auth0Helper from "utils/Auth0Helper";
import { useTenant } from "../TenantContext";

const SelectOptionWithIcon = lazy(() => import("../SelectOptionWithIcon"));
const Select = lazy(() => import("react-select"));

function Header(props) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [color, setColor] = React.useState("transparent");
  const sidebarToggle = React.useRef();
  const { user, logout, getAccessTokenSilently } = useAuth0();
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [isTenantComboLoading, setIsTenantComboLoading] = useState(true);
  const [selectedTenant, setSelectedTenant] = useState();

  const [countryOptions, setCountryOptions] = useState();
  const { getEnabledCountries } = Auth0Helper();
  const { setTenant } = useTenant();

  useEffect(() => {
    initializeComponent();
  }, []);

  const initializeComponent = async () => {
    const enabledCountries = await getEnabledCountries();

    const accessToken = await getAccessTokenSilently();
    await searchCountry({ size: 999999, page: 0 }, accessToken).then((result) => {
      let options = buildEnabledCountriesOptions(result.data.content, enabledCountries, true);

      setCountryOptions(options);

      const tenantSelected = options.find((x) => x.value.paiAbv == localStorage.getItem("pai"));

      setSelectedTenant(tenantSelected);
      setTenant(tenantSelected);
      setIsTenantComboLoading(false);
    });
  };

  const onLogOut = () => {
    localStorage.clear();
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const toggle = () => {
    if (isOpen) {
      setColor("transparent");
    } else {
      setColor("dark");
    }
    setIsOpen(!isOpen);
  };

  const dropdownToggle = (e) => {
    setDropdownOpen(!dropdownOpen);
  };

  const getBrand = () => {
    let brandName = "Home";
    routes.map((prop, key) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        brandName = prop.title;
      } else {
        if (prop.childrens) {
          prop.childrens.map((childProp, key) => {
            if (window.location.href.indexOf(childProp.layout + childProp.path) !== -1) {
              brandName = childProp.title;
            }
          });
        }
      }
      return null;
    });
    return brandName;
  };

  // function that adds color dark/transparent to the navbar on resize (this is for the collapse)
  const updateColor = () => {
    if (window.innerWidth < 993 && isOpen) {
      setColor("dark");
    } else {
      setColor("transparent");
    }
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateColor.bind(this));
  });

  const toggleSidebar = () => {
    setSidebarExpanded(!sidebarExpanded);
    props.onToggleSideBar();
  };

  const handleChangeTenant = (event) => {
    setTimeout(() => {
      const tenantSelected = event?.value;
      localStorage.setItem("pai", tenantSelected.paiAbv);
      setTenant(tenantSelected);
    }, 500);
  };

  const onChangeEnviroment = () => {
    localStorage.clear();
    window.location.href = process.env.REACT_APP_MULTI_LOGIN_URL;
  };

  return (
    // add or remove classes depending if we are on full-screen-maps page or not
    <Navbar expand="lg" className="navbar sticky-top navbar-transparent navbar-index-top">
      <Container fluid className="p-0">
        <Col md={6} className="p-0">
          {!props.isLoginView && (
            <>
              {sidebarExpanded ? (
                <a onClick={toggleSidebar} className="pr-2" style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faCircleChevronLeft} />
                </a>
              ) : (
                <a onClick={toggleSidebar} className="pr-3" style={{ fontSize: 20 }}>
                  <FontAwesomeIcon icon={faCircleChevronRight} />
                </a>
              )}
            </>
          )}

          <div className="navbar-wrapper">
            {!props.isLoginView && <NavbarBrand style={{ fontSize: 18 }}>{getBrand()}</NavbarBrand>}
          </div>
        </Col>
        <Col md={3} className="p-0 text-center d-flex justify-content-end">
          {!props.isLoginView && !isTenantComboLoading && (
            <Select
              defaultValue={selectedTenant}
              className="select-width"
              closeMenuOnSelect={true}
              components={{ Option: IconOption, SingleValue }}
              options={countryOptions}
              isMulti={false}
              isSearchable={false}
              isClearable={false}
              onChange={handleChangeTenant}
              //
              isDisabled={false}
              isLoading={false}
              placeholder="Select a country to operate"
            />
          )}
        </Col>
        <Col md={3} className="p-0">
          <Collapse isOpen={isOpen} navbar className="justify-content-end">
            <Nav navbar>
              <Dropdown inNavbar isOpen={dropdownOpen} toggle={(e) => dropdownToggle(e)}>
                <div className="profile-div">
                  <div className="profile-picture">
                    <img src={user.picture}></img>
                  </div>
                  <div className="profile-info">
                    <p>{user.nickname}</p>
                    <p>{user.email}</p>
                  </div>
                </div>
                <DropdownToggle caret nav className="float-right">
                  <FontAwesomeIcon icon={faDoorOpen} size="2x" />
                  <p>
                    <span className="d-lg-none d-md-block">Some Actions</span>
                  </p>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem onClick={onChangeEnviroment} className="dropdown-item-menu">
                    Change enviroment
                  </DropdownItem>

                  <DropdownItem onClick={onLogOut} className="dropdown-item-menu">
                    Log Out
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </Nav>
          </Collapse>
        </Col>
      </Container>
    </Navbar>
  );
}

const { Option } = components;
const IconOption = (props) => (
  <Option {...props}>
    <SelectOptionWithIcon {...props} />
  </Option>
);

const SingleValue = (props) => (
  <components.SingleValue {...props}>
    <SelectOptionWithIcon {...props} />
  </components.SingleValue>
);

export default Header;
